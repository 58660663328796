<template>
  <div v-if="!isLoading" id="service-offers">
    <header-service
      :service="service"
      currentTab="Ofertas"
      :isSaving="!isLoading && isSaving"
      @saving="save"
    ></header-service>
    <div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>
      <div class="col-lg-9 col-md-8">
        <u-portlet v-show="isLoading" title="Ofertas">
          <loading-service :enabled="isLoading"></loading-service>
        </u-portlet>
        <div ref="form">
          <div
            v-for="(offer, offerIndex) in offers"
            :key="offerIndex"
            class="m-portlet m-portlet--mobile m-portlet--body-progress-"
          >
            <div class="m-portlet__head">
              <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                  <h3 class="m-portlet__head-text">
                    <a
                      class="mr-3 text-muted"
                      href="javascript:;"
                      @click="toggleOffer(offerIndex)"
                    >
                      <i class="fas fa-angle-down"></i>
                    </a>
                    {{ offer.name === "" ? "Nova Oferta" : offer.name }}
                  </h3>
                </div>
              </div>
              <div class="m-portlet__head-tools">
                <a
                  v-show="offerIndex > 0"
                  href="javascript:;"
                  @click="removeOffer(offerIndex)"
                >
                  <i class="fas fa-times"></i>
                </a>
              </div>
            </div>
            <!-- START OFFER CONTENT -->
            <div
              class="m-portlet__body offer-content"
              :ref="`offer-content-${offerIndex}`"
            >
              <!-- START OFFER BASIC INFO -->
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Nome da Oferta</label>
                  <input
                    :disabled="inputDisable"
                    class="form-control"
                    v-model="offer.name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label>Plano</label>
                  <select
                    :disabled="inputDisable"
                    class="form-control"
                    v-model="offers[offerIndex].plan"
                    @change="setupOffer(offerIndex)"
                  >
                    <option value="weekly">Semanal</option>
                    <option value="monthly">Mensal</option>
                    <option value="daily">Diário</option>
                    <option value="loose">Avulso</option>
                    <option value="microbilling">Microbilling</option>
                    <option value="bundle">Bundle</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Consent Page</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="`${baseUrl}-${offers[offerIndex].alias}`"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label>Período de Degustação</label>
                  <input
                    :disabled="inputDisable || offer.is_microbilling"
                    :ref="`trial-days-${offerIndex}`"
                    type="number"
                    min="0"
                    class="form-control"
                    v-model="offer.trialDays"
                  />
                </div>
                <div class="form-group col-md-3">
                  <label>Método de Pagamento</label>
                  <select
                    :disabled="inputDisable"
                    class="form-control"
                    v-model="offer.paymentMethod"
                  >
                    <option value="carrier-billing" selected>
                      Carrier Billing
                    </option>
                    <option value="credit-card" selected>
                      Cartão de Crédito
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label class="mt-2">Tipos de Plano</label>
                  <div class="form-row">
                    <div class="mt-2">
                      <div class="form-check form-check-inline ml-3">
                        <div class="custom-control custom-checkbox mr-3">
                          <input
                            :disabled="inputDisable"
                            type="checkbox"
                            v-model="offer.plans"
                            class="custom-control-input"
                            value="1"
                            :id="`pre-${offerIndex}`"
                          />
                          <label
                            class="custom-control-label"
                            :for="`pre-${offerIndex}`"
                          >
                            Pré Pago
                          </label>
                        </div>
                        <div
                          class="custom-control custom-checkbox mr-3"
                          v-if="offer.plan === 'monthly'"
                        >
                          <input
                            :disabled="inputDisable"
                            type="checkbox"
                            v-model="offer.plans"
                            class="custom-control-input"
                            value="2"
                            :id="`pos-${offerIndex}`"
                          />
                          <label
                            class="custom-control-label"
                            :for="`pos-${offerIndex}`"
                          >
                            Pós Pago
                          </label>
                        </div>
                        <div
                          class="custom-control custom-checkbox mr-3"
                          v-if="offer.plan === 'monthly'"
                        >
                          <input
                            :disabled="inputDisable"
                            type="checkbox"
                            v-model="offer.plans"
                            class="custom-control-input"
                            value="3"
                            :id="`control-${offerIndex}`"
                          />
                          <label
                            class="custom-control-label"
                            :for="`control-${offerIndex}`"
                          >
                            Controle
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Offer Key</label>
                  <input
                    maxlength="29"
                    :disabled="!offer.key_manually || inputDisable"
                    class="form-control"
                    v-model="offer.key"
                  />
                  <span
                    v-show="!offer.key_manually"
                    class="m-form__help"
                    style="font-size: 10px"
                    >A Offer Key será gerada automaticamente</span
                  >
                </div>

                <div
                  v-if="$authorization.can('service-offer-key-manually')"
                  class="form-group col-6"
                >
                  <div class="switch" style="margin-top: 30px">
                    <switches
                      v-model="offer.key_manually"
                      color="green"
                    ></switches>
                    <div class="switch-label">
                      Gerar a Offer Key manualmente
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-12">
                  <div class="switch">
                    <switches
                      :disabled="inputDisable"
                      :ref="`offer-renewable-${offerIndex}`"
                      v-model="offer.renewable"
                      color="green"
                    ></switches>
                    <div class="switch-label">
                      Esta oferta possui renovação automática
                    </div>
                  </div>
                </div>
              </div>
              <div :ref="`has-tariff-${offerIndex}`">
                <div class="mt-4">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="float-left mt-1 pt-3">Tarifas</h5>
                      <button
                        :disabled="inputDisable"
                        v-show="!offer.is_microbilling"
                        class="btn m-btn btn-sm btn-info float-right"
                        @click="addStepdown(offerIndex)"
                      >
                        <i class="la la-plus"></i>
                        Tarifa Degrau
                      </button>
                      <div class="clearfix"></div>
                      <div class="m-separator mt-2"></div>
                    </div>
                  </div>
                </div>
                <!-- START STEPDOWN CONTENT -->
                <div
                  class="tariff pt-3"
                  v-for="(tariff, stepdownIndex) in offer.tariff"
                  :key="stepdownIndex"
                >
                  <div class="form-group">
                    <div
                      class="align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center"
                    >
                      <div class="w-10 w-xs-100 mr-4">
                        <label v-if="stepdownIndex > 0">Tarifa</label>
                        <label v-else>Tarifa Full</label>
                        <money
                          :disabled="inputDisable"
                          v-model="tariff.price"
                          v-bind="moneyOptions"
                          class="form-control"
                        ></money>
                      </div>
                      <div class="w-10 w-xs-100 mr-4">
                        <label>Periodicidade (dias)</label>
                        <input
                          type="number"
                          :ref="`tariff-renew-${offerIndex}-${stepdownIndex}`"
                          min="0"
                          :disabled="stepdownIndex > 0 || inputDisable"
                          class="form-control"
                          @change="
                            setSamePeriodRenewForOffer(
                              offerIndex,
                              tariff.periodRenew
                            )
                          "
                          v-model="tariff.periodRenew"
                        />
                      </div>
                      <div class="w-30 w-xs-100 mr-4">
                        <label>Período de vigência desta tarifa</label>
                        <div class="input-group">
                          <date-picker
                            :disabled="inputDisable || stepdownIndex > 0"
                            v-model="tariff.periodStart"
                            :config="datePickerOptions"
                            @dp-change="
                              setSamePeriodStartForOffer(
                                offerIndex,
                                tariff.periodStart
                              )
                            "
                            placeholder="Início"
                          ></date-picker>
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="far fa-calendar"></i>
                            </span>
                          </div>
                          <date-picker
                            v-model="tariff.periodEnd"
                            :id="
                              `tariff-period-finish-${offerIndex}-${stepdownIndex}`
                            "
                            :config="datePickerOptions"
                            placeholder="Término"
                            disabled="true"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="w-25 w-xs-100 mb-0">
                        <div class="switch mt-3">
                          <switches
                            :disabled="inputDisable"
                            v-model="tariff.periodUndefined"
                            color="green"
                            :emit-on-mount="
                              lockTariffPeriodEnd(offerIndex, stepdownIndex)
                            "
                          ></switches>
                          <div class="switch-label">Indeterminado</div>
                        </div>
                      </div>
                      <div class="w-5 w-xs-100 mb-0 mt-4" style="width: 10px">
                        <a
                          href="javascript:;"
                          v-show="stepdownIndex > 0"
                          @click="removeStepdown(offerIndex, stepdownIndex)"
                        >
                          <i class="fas fa-times"></i>
                        </a>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
                <!-- END STEPDOWN CONTENT -->
              </div>
            </div>
            <!-- END OFFER CONTENT -->
          </div>
          <div class="row mb-5" v-if="!isLoading">
            <div class="col-12 mt-4">
              <div class="text-center">
                <button
                  :disabled="inputDisable"
                  type="button"
                  @click="addOffer"
                  class="btn btn-success text-uppercase"
                >
                  <i class="fas fa-plus"></i>
                  nova oferta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue-bootstrap-datetimepicker";
import Switches from "vue-switches";
import { Money } from "v-money";
import HeaderService from "../components/HeaderService";
import UPortlet from "@/components/UPortlet";
import MenuService from "../components/MenuService";
import LoadingService from "../components/LoadingService";
import ServiceService from "../services/ServiceService";
import OfferService from "../services/OfferService";

export default {
  name: "service-offers",
  components: {
    UPortlet,
    MenuService,
    HeaderService,
    LoadingService,
    Money,
    DatePicker,
    Switches
  },
  data() {
    return {
      baseUrl: "http://consent.clarosgvas.mobicare.com.br/VENDA-SITE-NOVO",
      isLoading: true,
      isSaving: false,
      money: null,
      service: {},
      serviceService: new ServiceService(),
      offerService: new OfferService(),
      inputDisable: !this.$authorization.can("service-update"),
      datePickerOptions: {
        format: "DD/MM/YYYY",
        useCurrent: false
      },
      moneyOptions: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false
      },
      offerDefault: {
        id: null,
        serviceId: null,
        name: "",
        key: "",
        trialDays: 0,
        paymentMethod: "carrier-billing",
        plans: [],
        renewable: true,
        is_microbilling: false,
        tariff: [
          {
            price: 0,
            periodRenew: null,
            periodStart: null,
            periodEnd: null,
            periodUndefined: true
          }
        ]
      },
      offers: []
    };
  },
  created() {
    this.getService();
  },
  watch: {
    "$store.state.service.lock": function(newValue, oldValue) {
      this.lockForm(newValue);
    }
  },
  methods: {
    save() {
      this.isSaving = true;
      this.offerService
        .save(this.service.id, this.offers)
        .then(response => {
          console.log(response.data);
          this.getOffers();
          this.isSaving = false;
          swal("Sucesso!", "Ofertas atualizadas com sucesso.", "success");
          this.service.status_register = "updating";
        })
        .catch(error => {
          this.isSaving = false;
          swal("Ops!", this.$helper.formRequestErrorsMessage(error), "error");
        });
    },
    /**
     * Get Service Info
     */
    getService() {
      let promise = this.serviceService
        .getService(this.$route.params.id)
        .then(response => {
          this.isLoading = false;
          this.service = response.data;
          this.getOffers();
          this.preventEditForm();
        });

      let vm = this;

      $.when(promise).done(function() {
        vm.preventEditForm();
      });
    },
    /**
     * Get Service Offers
     */
    getOffers() {
      this.offerService
        .offers(this.service.id)
        .then(response => {
          const offers = response.data;

          if (offers && offers.length > 0) {
            this.offers = offers;
          } else {
            this.addOffer();
          }

          Object.keys(this.offers).forEach(key => {
            this.checkTypePlans(key);
          });
        })
        .catch(error => {
          console.log(error.message);
          this.addOffer();
        });
    },
    /**
     * Add dynamic offer block
     */
    addOffer() {
      let offerAux = JSON.parse(JSON.stringify(this.offerDefault));
      this.offers.push(offerAux);

      setTimeout(() => {
        const index = this.offers.length - 1;
        const offerContent = this.$refs["offer-content-" + index];
        if (offerContent) {
          offerContent[0].setAttribute("style", "display:block;");
        }
      }, 50);
    },
    /**
     * Add dynamic stepdown block
     * @param offerIndex
     */
    addStepdown(offerIndex) {
      let offerAux = JSON.parse(JSON.stringify(this.offerDefault));
      let tariffAux = Object.assign({}, offerAux.tariff[0]);
      tariffAux.periodRenew =
        (this.offers[offerIndex].tariff[0] &&
          this.offers[offerIndex].tariff[0].periodRenew) ||
        null;
      tariffAux.periodStart = this.offers[offerIndex].tariff[0].periodStart;
      this.offers[offerIndex].tariff.push(tariffAux);
    },

    setSamePeriodRenewForOffer(offerIndex, periodRenew) {
      this.offers[offerIndex].tariff.map(tariff => {
        tariff.periodRenew = periodRenew;
      });
    },

    setSamePeriodStartForOffer(offerIndex, periodStart) {
      this.offers[offerIndex].tariff.map(tariff => {
        tariff.periodStart = periodStart;
      });
    },

    checkServiceCampaign(offer) {
      if (offer.quantity_campaign) {
        swal(
          {
            title: "Oferta não pode ser excluída!",
            html:
              'Existe uma campanha criada com esta oferta. Contate o nosso suporte caso deseje prosseguir com a exclusão: <a target="_blank" href="mailto:svaclaro@uotz.com.br">svaclaro@uotz.com.br</a>',
            type: "warning",
            showCancelButton: false
          },
          "success"
        );

        return false;
      }

      return true;
    },

    /**
     * Remove dynamic offer block
     * @param offerIndex
     */
    removeOffer(offerIndex) {
      const offer = this.offers[offerIndex];

      if (this.checkServiceCampaign(offer)) {
        swal({
          title: "Tem certeza que deseja Deletar?",
          text: "Uma vez deletado não poderá mais recuperar esse registro.",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, desejo deletar"
        }).then(willDelete => {
          if (willDelete.value) {
            this.offers.splice(offerIndex, 1);
          }
        });
      }
    },
    /**
     * Remove dynamic stepdown block
     * @param offerIndex
     * @param stepdownIndex
     */
    removeStepdown(offerIndex, stepdownIndex) {
      this.offers[offerIndex].tariff.splice(stepdownIndex, 1);
    },
    /**
     * Disable tariff period finish input by switch
     * @param offerIndex
     * @param stepdownIndex
     */
    lockTariffPeriodEnd(offerIndex, stepdownIndex) {
      const id = `tariff-period-finish-${offerIndex}-${stepdownIndex}`;
      const isPeriodUndefined = this.offers[offerIndex].tariff[stepdownIndex]
        .periodUndefined;
      const input = document.getElementById(id);

      if (isPeriodUndefined) {
        this.offers[offerIndex].tariff[stepdownIndex].periodEnd = null;
        if (input) {
          input.disabled = true;
        }
      } else {
        if (input) {
          input.disabled = false;
        }
      }
    },
    /**
     * Toogle offer content
     * @param index
     */
    toggleOffer(index) {
      const refName = "offer-content-" + index;
      const offerContent = this.$refs[refName];
      console.log(offerContent);
      if (offerContent) {
        $("#service-offers")
          .find(offerContent)
          .slideToggle(300);
      }
    },

    checkTypePlans(index) {
      if (this.offers[index].plan !== "monthly") {
        this.offers[index].plans = [1];
      }
    },

    setupOffer(index) {
      this.checkTypePlans(index);
      const offerType = this.offers[index].plan;
      let tariffFull = this.offers[index].tariff[0];
      let offerRenewable = $(`offer-renewable-${index}`);
      let hasTariff = $(this.$refs[`has-tariff-${index}`]);

      switch (offerType) {
        case "loose":
          hasTariff.show();
          offerRenewable.prop("disabled", true);
          this.offers[index].renewable = false;
          this.offers[index].is_microbilling = false;
          tariffFull.periodRenew = 0;
          //Lock fields
          $(this.$refs[`tariff-renew-${index}-0`]).prop("disabled", true);
          break;
        case "microbilling":
          hasTariff.show();
          offerRenewable.prop("disabled", true);
          this.offers[index].renewable = false;
          this.offers[index].trialDays = 0;
          this.offers[index].is_microbilling = true;
          tariffFull.periodRenew = 0;
          //Lock fields
          $(this.$refs[`tariff-renew-${index}-0`]).prop("disabled", true);
          $(this.$refs[`trial-days-${index}`]).prop("disabled", true);
          //Clean stepdown
          this.offers[index].tariff = [];
          this.offers[index].tariff.push(tariffFull);
          break;
        case "bundle":
          hasTariff.show();
          offerRenewable.prop("disabled", false);
          this.offers[index].is_microbilling = false;
          this.offers[index].trialDays = 0;
          tariffFull.price = 0;
          tariffFull.periodRenew = 0;

          //Lock fields
          $(this.$refs[`tariff-renew-${index}-0`]).prop("disabled", false);
          $(this.$refs[`trial-days-${index}`]).prop("disabled", true);
          //Clean stepdown
          this.offers[index].tariff = [];
          this.offers[index].tariff.push(tariffFull);
          break;
        default:
          hasTariff.show();
          offerRenewable.prop("disabled", false);
          this.offers[index].is_microbilling = false;
          //Lock fields
          $(this.$refs[`tariff-renew-${index}-0`]).prop("disabled", false);
          $(this.$refs[`trial-days-${index}`]).prop("disabled", false);
          //Clean stepdown
          this.offers[index].tariff = [];
          this.offers[index].tariff.push(tariffFull);
          break;
      }
    },
    lockForm(isLocked) {
      const form = $(this.$refs.form);
      if (isLocked) {
        form.find("input, textarea, select").prop("disabled", true);
      } else {
        form.find("input, textarea, select").prop("disabled", false);
      }
    },

    preventEditForm() {
      setTimeout(() => {
        if (this.service.status_register === "complete") {
          this.$store.commit("setLock", true);
          this.lockForm(true);
        }
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped></style>
