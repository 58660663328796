<template>
  <div v-if="enabled">
    <div class="row">
      <div class="col-12">
        <div class="m-blockui m-auto">
          <span>Carregando...</span>
          <span>
            <div class="m-loader m-loader--brand"></div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'service-loading',
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  created() {
    this.$store.commit('setLock', false);
  }
}
</script>